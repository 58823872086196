
const ExpCard = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="card-img">
            <img loading="lazy" src={props.icon} className="main-img" />
        </div>

        <div className="card-content">
            <h5 className="card-title">{props.title}</h5>
        </div>
      </div>
    </div>
  )
}

export default ExpCard;
import React from 'react'
import './Commitment.css'
import CallToAction from '../Home/CTA/CallToActions';
import lottie5 from "../../assets/animations/Lottie05.json";
import { useTranslation } from 'react-i18next'


const Commitment = () => {
  const {t} = useTranslation();

  return (
    <div className="section about-commitment" style={{"backgroundColor": "#fff"}}>
      <div className="container">
        <CallToAction
          title={t("Commitment To Our Clients")}
          para={t("We saved the most important reason for last, which is our dedication to the success and satisfaction of our traders.")}
          para2={t("At Supreme FX, our clients are never viewed as a number in a spreadsheet, which is why we aim to offer the best support, not only to those who just started trading but more experienced traders as well.")}
          image={lottie5} divID="lottie5"
        />
      </div>
    </div>
  )
}
CallToAction.defaultProps = {
    btnText: 'Get Started',
  };
export default Commitment
import React from 'react'
import './Founder.css'
import founderImg from '../../assets/founder-2.png'
import { useTranslation } from 'react-i18next'


const Founder = () => {
    const {t} = useTranslation();

  return (
    <div className='section about-founder-section'>
        <div className='container'>
            <div className='col-lg-12'>
                <div className='row outer-row'>
                    <div className='card'>
                        <div className='card-body'>
                            <div className='row'>
                                <div className='col-lg-6 col-xs-12'>
                                    <div className='founder-img'>
                                        <img src={founderImg} alt='' />
                                    </div>
                                </div>

                                <div className='col-lg-6 col-xs-12'>
                                    <div className='founder-content'>
                                        <div className='founder-words'>
                                        <p className='p1-24' style={{"fontWeight": "500"}}>{t('Successful Forex trading is not about predicting the future; it’s about managing probabilities and making informed decisions based on available Information.')}</p>
                                        </div>
                                        
                                        <div className='founder-name'>
                                            <h6 className='founder-name-color'>{t('Samir Jezzini')}</h6>
                                        </div>

                                        <div className='founder-designation'>
                                            <p className='p2'>{t('Founder')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Founder
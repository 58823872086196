import axios from 'axios';

const API_URL = 'https://fcsapi.com/api-v3/forex/multi_url';
const API_URL2 = 'https://fcsapi.com/api-v3/stock/latest';
const API_KEY = 'tNVZYXEcP7Gw5lCOLMHm0SNo';

export const getForexData = async () => {
  try {
    const response = await axios.get(API_URL, {
      params: {
        'url[1]': 'https://fcsapi.com/api-v3/forex/latest?symbol=AUD/USD,EUR/CHF,EUR/GBP,EUR/USD,GBP/CHF,GBP/USD,NZD/USD,USD/CHF,AUD/CAD,XAU/USD,BTC/XAU,USD/BTC,USD/JPY,USD/NZD,USD/AED,GBP/AUD,CHF/SGD,IDR/CHF,EUR/PKR,CAD/PKR&period=1h',
        'url[2]': `https://fcsapi.com/api-v3/forex/list?type=forex&access_key=${API_KEY}`,
        access_key: API_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching forex data:', error);
    throw error;
  }
};

export const getStockData = async () => {
  try {
    const response = await axios.get(API_URL2, {
      params: {
        'symbol': 'AAPL,NKE,MSFT,GOOG,TSLA,BRK.A,DIS,PEP,XOM,PFE,WMT,IBM,AXP,V,EBAY,QCOM,BIDU,SIRI,EA,NLOK,SBUX,UAL',
        'exchange': 'nasdaq,NYSE',
        access_key: API_KEY,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Stock data:', error);
    throw error;
  }
};
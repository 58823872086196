import Navigation from "../Common/Header/Header"
import Footer from "../Common/Footer/Footer"
import ScrollTop from "../Common/ScrollTop/ScrollTop"
import AccountTypeCard from './AccountTypeCard'
import './AccountType.css'
import { useTranslation } from "react-i18next"
import i18next from "i18next"

const AccountType = () => {
    const {t} = useTranslation();

    // const standardArray = ['Spread From 1.2 pips', 'Leverage 1:500', 'Minimum Deposit $200', 'Trading Platform MT5', 
    // 'Stop Out 30%', 'Margin Call 100%', 'Markets 300+ \nTrading Instruments']

    // const advancedArray = ['SpreadFrom 0.8 pips', 'Leverage 1:500', 'Minimum Deposit $1.000', 'Zero Commission', 
    // 'Trading Platform MT5', 'Stop Out 30%', 'Margin Call 100%', 'Markets 300+ \nTrading Instruments']
    
    // const proArray = ['SpreadFrom 0.1 to 0.2 pips', 'Leverage 1:200', 'Minimum Deposit $2.500', 'Zero Commission', 
    // 'Trading Platform MT5', 'Stop Out 45%', 'Margin Call 120%', 'Markets 300+ \nTrading Instruments']

    
  return (
    <>
    <Navigation />
    <ScrollTop />
    <div className='section account-type-section'>
        <div className='container'>
            <div className='col-md-12'>
                <div className='row text-center mb-5'>
                    <h1>{t('Account Types')}</h1>
                    <p className='p1-24'>{t('Choose the account type that suits your trading style and goals. Start trading today.')}</p>
                </div>
                <div className='row'>
                    <AccountTypeCard title={t("Standard")} content={i18next.t('standardArray', {returnObjects: true})} />
                    <AccountTypeCard title={t("Advanced")} content={i18next.t('advancedArray', {returnObjects: true})} />
                    <AccountTypeCard title={t("PRO")} content={i18next.t('proArray', {returnObjects: true})} />
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </>
  )
}

export default AccountType
import ArrowDark from "../../assets/svg/arrow-dark.svg"

const MarketNewsCard = (props) => {
  return (
    <div className='card'>
        <div className='card-body'>
            <p className='p2'>{props.content}</p>
            <div className="news-meta">
              <div className='author'>
                <img src={props.img} alt='' />
                <p className='p4'>{props.author}</p>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="357" height="2" viewBox="0 0 357 2" fill="none">
                <path opacity="0.4" d="M0 1L357 0.999969" stroke="#4D535F" strokeDasharray="3 3"/>
              </svg>
              <p className='p4'>{props.date}</p>
            </div>
            <a href="#" style={{"display": "flex", "justifyContent": "end"}}>
              <img src={ArrowDark} alt="" className="arrow-img" />
            </a>
        </div>
    </div>
  )
}

export default MarketNewsCard
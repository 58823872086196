import { useTranslation } from "react-i18next"
import Professionals from "../../assets/professional-teams.png"

const Breadcrum = () => {
    const {t} = useTranslation();

  return (
    <div className='section company-profile-breadcrum-section position-relative'>
        <div className='container'>
            <div className='col-lg-12'>
                <div className='center-content'>
                    <div className='content'>
                        <h1>{t('Company Profile')}</h1>
                        <p className='p2'>{t('ambition meets execution')}</p>
                    </div>

                    <div className='users-count'>
                        <h5>{t('20k+')}</h5>
                        <p className='p4'>{t('Users globally')}</p>
                    </div>
                </div>

                <div className='left-content'>
                    <div className='experience-card'>
                        <p className='p2'>{t('20+ Yrs of Experience')}</p>

                        <svg xmlns="http://www.w3.org/2000/svg" width="171" height="172" viewBox="0 0 171 172" fill="none">
                        <path d="M85.8815 3.76221C131.046 3.76221 167.659 40.4961 167.659 85.8097C167.659 131.123 131.046 167.857 85.8815 167.857C40.7168 167.857 4.10352 131.123 4.10352 85.8097" stroke="white" strokeWidth="6.64" strokeLinecap="round"/>
                        <path d="M85.8822 19.2739C122.508 19.2739 152.199 49.0631 152.199 85.81C152.199 122.557 122.508 152.346 85.8822 152.346C58.2298 152.346 34.5303 135.365 24.4463 110.91" stroke="white" strokeWidth="6.64" strokeLinecap="round"/>
                        <path d="M85.5514 35.6016C113.372 35.6016 135.924 58.0805 135.924 85.8098C135.924 113.539 113.372 136.018 85.5514 136.018C71.224 136.018 58.2936 130.056 48.8574 120.208" stroke="white" strokeWidth="6.64" strokeLinecap="round"/>
                        <path d="M85.4741 51.9292C104.349 51.9292 119.65 67.0979 119.65 85.8095C119.65 104.521 104.349 119.69 85.4741 119.69" stroke="white" strokeWidth="6.64" strokeLinecap="round"/>
                        <path d="M85.8813 69.0737C95.094 69.0737 102.562 76.5667 102.562 85.8098C102.562 95.0529 95.094 102.546 85.8813 102.546C76.6686 102.546 69.2002 95.0529 69.2002 85.8098" stroke="white" strokeWidth="6.64" strokeLinecap="round"/>
                        </svg>
                    </div>

                    <svg id="left-svg" xmlns="http://www.w3.org/2000/svg" width="161" height="226" viewBox="0 0 161 226" fill="none">
                        <circle opacity="0.15" cx="48" cy="113" r="113" fill="url(#paint0_linear_1324_7119)"/>
                        <defs>
                        <linearGradient id="paint0_linear_1324_7119" x1="161" y1="0" x2="-65" y2="0" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#FEE140"/>
                        <stop offset="1" stopColor="#FF0000"/>
                        </linearGradient>
                        </defs>
                    </svg>
                </div>

                <div className='right-content'>
                    <div className="professional-card">
                        <span>{t('Team of professionals')}</span>
                        <img src={Professionals} alt="" />
                    </div>

                    <svg id="right-svg" xmlns="http://www.w3.org/2000/svg" width="240" height="456" viewBox="0 0 240 456" fill="none">
                    <path opacity="0.1" d="M456 228C456 353.921 353.921 456 228 456C102.079 456 0 353.921 0 228C0 102.079 102.079 0 228 0C353.921 0 456 102.079 456 228Z" fill="url(#paint0_linear_1324_7120)"/>
                    <defs>
                    <linearGradient id="paint0_linear_1324_7120" x1="540.014" y1="373.135" x2="374.285" y2="-82.8646" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#21D4FD"/>
                    <stop offset="1" stopColor="#B721FF"/>
                    </linearGradient>
                    </defs>
                    </svg>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Breadcrum
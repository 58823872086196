import StockGraph from '../../../assets/svg/stock-graph.svg'
// import Bitcoin1 from '../../../assets/svg/bitcoin-1.svg'
import ArrowGreen from '../../../assets/svg/arrow-up-green.svg'

const LiveStockCard = (props) => {
  return (
    <div className='card'>
        <div className='card-body'>
            <div className='left-content'>
                <div className='upper-content'>
                    {/* <img src={Bitcoin1} alt='' /> */}
                    <img src={props.image} alt='' />
                    {/* <p className='p4-medium'>Bitcoin USD (BTC-USD)</p> */}
                    <p className='p4-medium'>{props.data['Global Quote']['01. symbol']} ({props.data['Global Quote']['01. symbol']})</p>
                </div>
                <div className='lower-content'>
                    {/* <p className='p5-medium green-text'>$26.070.91</p> */}
                    <p className='p5-medium green-text'>${props.data['Global Quote']['05. price']}</p>
                    <img src={ArrowGreen} alt='' />
                    {/* <p className='p5-medium ms-2'>0.0123 <span className='red-text'>0.012%</span></p> */}
                    <p className='p5-medium ms-2'>{props.data['Global Quote']['09. change']} <span className='red-text'>{props.data['Global Quote']['10. change percent']}</span></p>
                </div>
            </div>
            <div className='right-content'>
                <img src={StockGraph} alt='' />
            </div>
        </div>
    </div>
  )
}

export default LiveStockCard
import Trading from "../../assets/svg/trading.svg"
import Funding from "../../assets/svg/funding.svg"
import Supreme from "../../assets/svg/go-supreme.svg"
import Button from "../Common/Button/Button"
import { Link } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import i18next from "i18next"

const CompanyFeatures = () => {
    const {t} = useTranslation();
    const companyFeaturesRef = useRef(null);
  const [registrationLink, setRegistrationLink] = useState('');
  const [redirectLang, setRedirectLang] = useState('');


    useEffect(() =>{
        if (i18next.language === 'ar'){
            setRedirectLang('?lang=ar');
          } else{
            setRedirectLang('?lang=en');
          }

        if(window.location.hostname === 'supremefxtrading.sv' || window.location.hostname === 'www.supremefxtrading.sv'){
            setRegistrationLink(`https://secure.supremefxtrading.sv/register${redirectLang}`);
        } else if(window.location.hostname === 'supremefxtrading.net' || window.location.hostname === 'www.supremefxtrading.net'){
        setRegistrationLink(`https://secure.supremefxtrading.net/register`);
      } else{
            setRegistrationLink(`https://secure.supremefxtrading.com/register${redirectLang}`);
        }

        const compnayFeatureObserver = new IntersectionObserver(entry =>{
            console.log('test', entry);
            if (entry[0].isIntersecting){
              entry[0].target.classList.add('ctaBGFade');
              compnayFeatureObserver.unobserve(entry[0].target);
            }
        }, 
        {
          threshold: 0.3,
        }
        )
        compnayFeatureObserver.observe(companyFeaturesRef.current);
        
        return () => {
            compnayFeatureObserver.disconnect();
        };
    },[i18next.language, redirectLang])

  return (
    <div className='section company-features-section'>
        <div className="container">
            <div className="row" ref={companyFeaturesRef}>
                <div className="col-md-6 col-6">
                    <div className="card">
                    <div className="card-body">
                        <h6>{t('Go Supreme')}</h6>
                        <p className="p4">{t('Trade With Confidence: At Supreme FX, we believe in empowering our traders by eliminating unnecessary costs. That\'s why we offer 0% commission on all trades, allowing you to maximize your profits and make the most of every trading opportunity. In addition, our comprehensive set of tools provides you with the resources you need to succeed.')}</p>
                        <img src={Supreme} alt="" />
                    </div>
                    </div>
                </div>

                <div className="col-md-6 col-6">
                    <div className="card"
                    >
                    <div className="card-body">
                        <h6>{t('Funding & Withdrawwal')}</h6>
                        <p className="p4">{t('Directly linked to your SupremeFX trading account. Secure Online and Offline transactions. Withdraw your trading profits via ATMs globally. Online client support. A dedicated mobile app to manage your deposits and withdrawals with ease. Quickly Transfer Funds To And From Your SupremeFX Trading Account At SupremeFX, we understand the importance of providing flexible and convenient options for depositing and funding your trading account.')}</p>
                        <img src={Funding} alt="" />
                    </div>
                    </div>
                </div>

                <div className="col-md-6 col-6">
                    <div className="card">
                    <div className="card-body">
                        <h6>{t('Trading platform')}</h6>
                        <p className="p4">{t('Meta Trader 5 (MT5) is a powerful and versatile trading platform designed to meet the needs of both novice and experienced traders. It offers several advantages that make it a popular choice among traders worldwide.')}</p>
                        <img src={Trading} alt="" />
                    </div>
                    </div>
                </div>

                <div className="col-md-6 col-6 mt-4">
                    <div className="card last-card">
                        <div className="card-body">
                            <h6>{t('Get Started')}</h6>
                            <Link to={registrationLink}>
                                <Button className="theme-red-btn" btnTxt={t("Open an account")} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CompanyFeatures
import React, {useState} from 'react'

const AccordionItem = (props) => {
    const [isActive, setIsActive] = useState(false);
  return (
    <div className={`accordion-items ${isActive?`show`:''}`} onClick={() => setIsActive(!isActive)}>
      <div className="accordion-title">
        <h6>{props.title}</h6>
        <button className='accordion-toggle-btn'>{isActive ? '-' : '+'}</button>
      </div>
      <div className="accordion-content"><p className='p4'>{props.content}</p></div>
      {/* {isActive && <div className="accordion-content"><p className='p2'>{props.content}</p></div>} */}
    </div>
  )
}

export default AccordionItem
import React from 'react'
import './Breadcrum.css'
import yellowMark from '../../assets/svg/yellow-marker.svg'
import greyMark from '../../assets/svg/grey-marker.svg'
import redMark from '../../assets/svg/red-marker.svg'
import blueMark from '../../assets/svg/blue-marker.svg'
import greenMark from '../../assets/svg/green-marker.svg'
import { useTranslation } from 'react-i18next'

const Breadcrum = () => {
    const {t} = useTranslation();

  return (
    <div className='section about-breadcrum-section position-relative'>
        <div className='container'>
            <div className='col-md-12'>
                <div className='row'>
                    <div className='content'>
                        <h1>{t('About Us')}</h1>
                        <p className='p1-24'>{t('Discover who we are and what drives us to provide exceptional forex trading services to traders around the world.')}</p>
                    </div>

                    <div className='markers'>
                        <div className='yellow-mark' style={{"--i": "1"}}>
                            <img src={yellowMark} />
                            <p className='p1-24'>{t('Trust')}</p>
                        </div>
                        <div className='grey-mark' style={{"--i": "2"}}>
                            <img src={greyMark} />
                            <p className='p1-24'>{t('Integrity')}</p>
                        </div>
                        <div className='red-mark' style={{"--i": "3"}}>
                            <img src={redMark} />
                            <p className='p1-24'>{t('Customer centric')}</p>
                        </div>
                        <div className='blue-mark' style={{"--i": "4"}}>
                            <img src={blueMark} />
                            <p className='p1-24'>{t('Value')}</p>
                        </div>
                        <div className='green-mark' style={{"--i": "5"}}>
                            <img src={greenMark} />
                            <p className='p1-24'>{t('Reliable')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Breadcrum
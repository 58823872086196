import React from "react";
import CallToAction from "../Home/CTA/CallToActions";
import lottie4 from "../../assets/animations/Lottie04.json";
import { useTranslation } from 'react-i18next'
import "./Principles.css"
import { useEffect, useState } from "react";
import i18next from "i18next";


const Principles = () => {
  const {t} = useTranslation();
  const [registrationLink, setRegistrationLink] = useState('');
  const [redirectLang, setRedirectLang] = useState('');

  useEffect(() => {
    if (i18next.language === 'ar'){
      setRedirectLang('?lang=ar');
    } else{
      setRedirectLang('?lang=en');
    }

    if(window.location.hostname === 'supremefxtrading.sv' || window.location.hostname === 'www.supremefxtrading.sv'){
      setRegistrationLink(`https://secure.supremefxtrading.sv/register${redirectLang}`);
    }else if(window.location.hostname === 'supremefxtrading.net' || window.location.hostname === 'www.supremefxtrading.net'){
			setRegistrationLink(`https://secure.supremefxtrading.net/register`);
		}  else{
      setRegistrationLink(`https://secure.supremefxtrading.com/register${redirectLang}`);
    }
  }, [i18next.language, redirectLang])

  return (
    <div className="section about-principles">
      <div className="container">
        <CallToAction
          title={t("Our Principles")}
          para={t("To ensure traders receive superior order execution, Supreme FX has strong relationships with some of the leading pricing providers in the industry. Quality also extends to the client experience, where Supreme FX holds itself to high standard by offering VIP support and service to all traders.")}
          para2={t("Stable execution is paramount in forex trading. For this reason Supreme FX only cooperates with leading technology vendors who can ensure stability both day in and day out.")}
          image={lottie4} order="order-md-2" divID="lottieNew" btnText={t("Open Account")} isBtn link={registrationLink}
        />
      </div>
    </div>
  );
};

CallToAction.defaultProps = {
  btnText: 'Get Started',
};
export default Principles;

import Navigation from "../Common/Header/Header"
import Footer from "../Common/Footer/Footer"
import MarketNewsCard from "./MarketNewsCard"
import Author from "../../assets/author.png"
import "./MarketNews.css"
import LiveStockCard from "../Home/LiveStocks/LiveStockCard"
import { useTranslation } from 'react-i18next'


const MarketNews = () => {
  const {t} = useTranslation();

  return (
    <>
    <Navigation />
    <div className="section market-news-section">
        <div className="market-news-breadcrum">
            <div className="container">
                <div className="row">
                    <div className="content">
                        <h1>{t('Market News')}</h1>
                        <p className="p2">{t('Stay ahead with our expert insights. Make informed trades and investments with confidence.')}</p>
                    </div>
                </div>
            </div>
        

            <div className='live-stock-section'>
                <div className="row d-block">
                    <LiveStockCard />
                    <LiveStockCard />
                    <LiveStockCard />
                    <LiveStockCard />
                    <LiveStockCard />
                </div>
            </div>
        </div>
        
        <div className="container">
            <div className="row news-list" style={{"gap": "24px"}}>
                <MarketNewsCard img={Author} author="by Richard Snow" date="September 20, 2023" content="Euro Snapshot: EUR/USD on Fed Watch, UK CPI Tests EUR/GBP Range" />
                <MarketNewsCard img={Author} author="by Richard Snow" date="September 20, 2023" content="Euro Snapshot: EUR/USD on Fed Watch, UK CPI Tests EUR/GBP Range" />
                <MarketNewsCard img={Author} author="by Richard Snow" date="September 20, 2023" content="Euro Snapshot: EUR/USD on Fed Watch, UK CPI Tests EUR/GBP Range" />
                <MarketNewsCard img={Author} author="by Richard Snow" date="September 20, 2023" content="Euro Snapshot: EUR/USD on Fed Watch, UK CPI Tests EUR/GBP Range" />
                <MarketNewsCard img={Author} author="by Richard Snow" date="September 20, 2023" content="Euro Snapshot: EUR/USD on Fed Watch, UK CPI Tests EUR/GBP Range" />
                <MarketNewsCard img={Author} author="by Richard Snow" date="September 20, 2023" content="Euro Snapshot: EUR/USD on Fed Watch, UK CPI Tests EUR/GBP Range" />
            </div>
        </div>
    </div>
    <Footer />
    </>
  )
}

export default MarketNews
import React from 'react'
import Navigation from '../Common/Header/Header'
import Footer from '../Common/Footer/Footer'
import ScrollTop from '../Common/ScrollTop/ScrollTop'
import Breadcrum from './Breadcrum'
import Founder from './Founder'
import Team from './Team'
import Commitment from './Commitment'
import Principles from './Principles'

const About = () => {
  return (
    <>
    <Navigation />
    <ScrollTop />
    <Breadcrum />
    <Founder />
    <Team />
    <Commitment />
    <Principles />
    <Footer />
    </>
  )
}

export default About
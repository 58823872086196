
import React, { useEffect, useRef, useState } from 'react';
import Navigation from "../Common/Header/Header"
import Footer from "../Common/Footer/Footer"
import Trophy from '../../assets/svg/trophy.svg'
import Meeting from '../../assets/svg/meeting.svg'
import AnalyzeData from '../../assets/svg/analyze-data.svg'
import './Accounts.css'
import Button from "../Common/Button/Button"
import i18next from "i18next"


const Accounts = () => {
	
	const [registrationLink, setRegistrationLink] = useState('');
	const [redirectLang, setRedirectLang] = useState('');

	useEffect(() => {
		if (i18next.language === 'ar'){
            setRedirectLang('?lang=ar');
          } else{
            setRedirectLang('?lang=en');
          }

        if(window.location.hostname === 'supremefxtrading.sv' || window.location.hostname === 'www.supremefxtrading.sv'){
            setRegistrationLink(`https://secure.supremefxtrading.sv/register${redirectLang}`);
        }else if(window.location.hostname === 'supremefxtrading.net' || window.location.hostname === 'www.supremefxtrading.net'){
			setRegistrationLink(`https://secure.supremefxtrading.net/register`);
		} else{
            setRegistrationLink(`https://secure.supremefxtrading.com/register${redirectLang}`);
        }
	},[]);
	
  return (
    <>
    <Navigation />
    <div className="section account-section">
        <div className="container">
            <div className="col-lg-12">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="account-content">
                            <div className="heading">
                                <h5>Open Demo Account</h5>
                            </div>
                            <div className="para">
                                <p className="p4">Practice your trading strategies with zero risk using our demo accounts, and prepare for success in the live markets.</p>
                            </div>
                        </div>

                        <div className="account-points">
                            <div className="items">
                                <div className="icon">
                                    <img src={Trophy} alt="" />
                                </div>
                                <div className="item-heading">
                                    <p className="p2">Assured Regulation</p>
                                </div>
                                <div className="item=para">
                                    <p className="p4">Feel secure with a trusted provider.</p>
                                </div>
                            </div>

                            <div className="items">
                                <div className="icon">
                                    <img src={Meeting} alt="" />
                                </div>
                                <div className="item-heading">
                                    <p className="p2">Seamless Experience</p>
                                </div>
                                <div className="item=para">
                                    <p className="p4">Comprehensively access best-in-class trading opportunities.</p>
                                </div>
                            </div>

                            <div className="items">
                                <div className="icon">
                                    <img src={AnalyzeData} alt="" />
                                </div>
                                <div className="item-heading">
                                    <p className="p2">Extensive Markets</p>
                                </div>
                                <div className="item=para">
                                    <p className="p4">Unleash a wide range of global markets.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <a href="https://secure.supremefxtrading.com/register-demo" target="_blank">
                            <Button className="btn theme-red-btn" btnTxt="Open Demo Account" />
                        </a>
                        
                        <a href={registrationLink} className="mt-5 d-block" target="_blank">
                            <Button className="btn theme-red-btn" btnTxt="Open Live Account" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </>
  )
}

export default Accounts
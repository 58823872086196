import Navigation from "../Common/Header/Header"
import Footer from "../Common/Footer/Footer"
import MissionVision from "./MissionVision"
import Leader from "./Leader"
import "./CompanyProfile.css"
import AccountTypes from "./AccountTypes"
import CoreValues from "./CoreValues"
import CompanyFeatures from "./CompanyFeatures"
import Breadcrum from "./Breadcrum"

const CompanyProfile = () => {
  return (
    <>
    <Navigation />
    <Breadcrum />
    <MissionVision />
    <Leader />
    <CoreValues />
    <AccountTypes />
    <CompanyFeatures />
    <Footer />
    </>
  )
}

export default CompanyProfile
import lottie1 from "../../../assets/animations/Lottie01.json";
import lottie2 from "../../../assets/animations/Lottie02.json";
import lottie3 from "../../../assets/animations/Lottie03.json";
import lottie4 from "../../../assets/animations/Export v2.json";
import "../../../index.css";
import CallToAction from "./CallToActions";
import "./CTAAnimation.css"
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import i18next from "i18next";

const CTA = () => {
  const {t} = useTranslation();
  const [registrationLink, setRegistrationLink] = useState('')
  const [demoRegistrationLink, setDemoRegistrationLink] = useState('')
  const [portalLink, setPortalLink] = useState('')
  const [productLink, setProductLink] = useState('')
  const [redirectLang, setRedirectLang] = useState('');


  useEffect(() => {
    if (i18next.language === 'ar'){
		setRedirectLang('?lead_source=website');
	} else{
		setRedirectLang('?lead_source=website');
	}

    if(window.location.hostname === 'supremefxtrading.sv' || window.location.hostname === 'www.supremefxtrading.sv'){
      setRegistrationLink(`https://secure.supremefxtrading.sv/register${redirectLang}`);
      setDemoRegistrationLink(`https://secure.supremefxtrading.sv/register-demo${redirectLang}`);
      setPortalLink("http://portal.supremefxtrading.sv/");
      setProductLink("/our-product/");
    } else if(window.location.hostname === 'supremefxtrading.net' || window.location.hostname === 'www.supremefxtrading.net'){
        setRegistrationLink(`https://secure.supremefxtrading.net/register`);
		setDemoRegistrationLink(`https://secure.supremefxtrading.net/register-demo${redirectLang}`);
		setPortalLink("http://portal.supremefxtrading.net/");
		setProductLink("/our-product/");
      }else{ 
      setRegistrationLink(`https://secure.supremefxtrading.com/register${redirectLang}`);
      setDemoRegistrationLink(`https://secure.supremefxtrading.com/register-demo${redirectLang}`);
      setProductLink("/our-product/");
    }
  }, [i18next.language, redirectLang]);

  return (
    <div className="section cta-section">
      <div className="container">
        <CallToAction
          title={t("New to Trading?")}
          para={t("Start with a demo account and enjoy the experience of trading without risking your own money.")}
          image={lottie1} divID="lottie1" isBtn btnText={t("Open Demo Account")} link={demoRegistrationLink}
        />
        <CallToAction
          title={t("Ready to Go Live?")}
          para={t("Powerful investment platform to help you grow and manage your money.")}
          image={lottie2} order="order-md-2" divID="lottie2" isBtn btnText={t("Open Live Account")} link={registrationLink}
        />
        <CallToAction
          title={t("Become a Partner")}
          para={t("Introducing Broker (IB) Program - You can earn a volume-based IB rebates for every client you refer to us when they trade.")}
          para2={t("Designed for those with a strong trader audience, such as:")}
          image={lottie3} divID="lottie3" liItems isBtn btnText={t("Get Started")} link={portalLink}
        />
        <CallToAction
          title={t("Our Products")}
          para={t("Start trading with more than 500+ instruments including Currencies, Indices, Metals, Commodities, CFD Stocks, CFD Cryptos, CFD ETFs & Equities with excellent conditions.")}
          image={lottie4} order="order-md-2" divID="lottie4" isBtn btnText={t("Get Started")} link={productLink}
        />
      </div>
    </div>
  );
};

export default CTA;

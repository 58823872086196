import Navigation from "../Common/Header/Header"
import Footer from "../Common/Footer/Footer"
// import Trophy from '../../assets/svg/trophy.svg'
import Trophy from '../../assets/svg/trophy.png'
// import Meeting from '../../assets/svg/meeting.svg'
import Meeting from '../../assets/svg/meeting.png'
// import AnalyzeData from '../../assets/svg/analyze-data.svg'
import AnalyzeData from '../../assets/svg/analyze-data.png'
import Button from "../Common/Button/Button"
import "./IntroducingBroker.css"
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

const IntroducingBroker = () => {
  const {t} = useTranslation();
  const [portalLink, setPortalLink] = useState('')

  const [rangeValue, setRangeValue] = useState(0)
  const [selectValue, setSelectValue] = useState(0)

  const handleOnchangeSelect = (value) => {
    const deposit_value = document.getElementById('deposit_value');
    deposit_value.innerHTML = value * 10;
    setSelectValue(value);
  };

  useEffect(() => {
    if(window.location.hostname === 'supremefxtrading.sv' || window.location.hostname === 'www.supremefxtrading.sv'){
        setPortalLink("http://portal.supremefxtrading.sv/");
    } else if(window.location.hostname === 'supremefxtrading.net' || window.location.hostname === 'www.supremefxtrading.net'){
		setPortalLink("http://portal.supremefxtrading.net/");
	}else{
        setPortalLink("http://portal.supremefxtrading.com/");
    }
  }, [])

  return (
    <>
    <Navigation />
    <div className="section account-section">
        <div className="container">
            <div className="col-lg-12">
                <div className="row">
                    <div className="heading text-center">
                        <h1>{t('Introducing Broker')}</h1>
                        <p className="p2">{t('Partner with us as an IB and earn generous commissions by referring clients to our top-tier forex services.')}</p>
                    </div>
                </div>

                <div className="row">
                    <div className="broker-calc">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card clients">
                                    <h6>{t('Brokers Profits')}</h6>

                                    <div className="form-group">
                                        <label htmlFor="" className="p2">{t('Deposit category')}</label>
                                        <select className="form-control" onChange={(e) => {handleOnchangeSelect(e.target.value)}}>
                                            <option selected disabled>{t('Select Value')}</option>
                                            <option value="1">1k Deposit</option>
                                            <option value="5">5k Deposit</option>
                                            <option value="10">10k Deposit</option>
                                            <option value="20">20k Deposit</option>
                                            <option value="100">100k Deposit</option>
                                        </select>
                                    </div>

                                    <div className="clients-count">
                                        <p className="p2">Clients</p> 
                                        <p className="p4">{rangeValue}</p>
                                    </div>
                                    
                                    <input type="range" min="0" max="1000" step="1" value={rangeValue} onChange={(e) => {setRangeValue(e.target.value)}}/>
                                    
                                    <div className="slider-range">
                                        <span className="least-value">0</span>
                                        <span className="most-value">1000</span>
                                    </div>

                                    <p className="p4">{t('Use the Slider to Calculate the average monthly income you can make as our affiliate partner.')}</p>

                                <Link to={portalLink} target='_blank' rel='noreferrer'>
                                <Button className="theme-red-btn" btnTxt={t("Get Started")} />
                                </Link>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="card calculation">
                                    <div className="top-content">
                                        <h6>{t('Calculation for AVG lots')}</h6>
                                        <p className="p4">{t('/ Month')}</p>
                                    </div>

                                    <div className="middle-content">
                                        <table>
                                            <tr>
                                                <th>Name</th>
                                                <th>Total</th>
                                            </tr>
                                            <tr>
                                                <td>Deposit Category</td>
                                                <td id="deposit_value">0</td>
                                            </tr>
                                            <tr>
                                                <td>Clients</td>
                                                <td id="client_value">{rangeValue}</td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div className="bottom-content">
                                        <p className="p2">{t('Revenue Per Month')}</p>
                                        <h6>$ {80 * rangeValue * selectValue}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">

                        <div className="account-points">
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="items">
                                        <div className="icon">
                                            <img src={Trophy} alt="" />
                                        </div>
                                        <div className="item-heading">
                                            <p className="p2">{t('Assured Regulation')}</p>
                                        </div>
                                        <div className="item=para">
                                            <p className="p4">{t('Feel secure with a trusted provider.')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="items">
                                        <div className="icon">
                                            <img src={Meeting} alt="" />
                                        </div>
                                        <div className="item-heading">
                                            <p className="p2">{t('Seamless Experience')}</p>
                                        </div>
                                        <div className="item=para">
                                            <p className="p4">{t('Comprehensively access best-in-class trading opportunities.')}</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4">
                                    <div className="items">
                                        <div className="icon">
                                            <img src={AnalyzeData} alt="" />
                                        </div>
                                        <div className="item-heading">
                                            <p className="p2">{t('Extensive Markets')}</p>
                                        </div>
                                        <div className="item=para">
                                            <p className="p4">{t('Unleash a wide range of global markets.')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 text-center mt-5">
                        <Link to={portalLink} target="_blank" rel="noreferrer">
                            <Button className="btn theme-red-btn" btnTxt="Become Partner"></Button>
                        </Link>
                        {/* <div className='contact-form'>
                            <form type="POST" action='#'>
                                <div className="form-group">
                                    <label htmlFor="first_name" className="form-label">{t('First Name')}</label>
                                    <input type="text" className="form-control" id="first_name" placeholder={t('First Name')} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="last_name" className="form-label">{t('Last Name')}</label>
                                    <input type="text" className="form-control" id="last_name" placeholder={t('Last Name')} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="country" className="form-label">{t('Country')}</label>
                                    <select className="form-control">
                                        <option>Saudi Arab</option>
                                        <option>UAE</option>
                                    </select>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="phone" className="form-label">{t('Phone')}</label>
                                    <input type="text" className="form-control" id="phone" placeholder={t('Phone Number')} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="email_address" className="form-label">{t('Email Address')}</label>
                                    <input type="email" className="form-control" id="email_address" placeholder={t("Email Address")} />
                                </div>

                                <div className="row">
                                    <div className="col">
                                        <div className="form-group">
                                            <Button className="w-100 btn theme-red-btn" btnTxt={t("Send Pin")} />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="form-group">
                                            <input type="text" className="form-control" id="pin" placeholder={t("Enter Pin")} />
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="password" className="form-label">{t('Password')}</label>
                                    <input type="password" className="form-control" id="password" placeholder={t('Password')} />
                                </div>
                                <Button className="w-100 btn theme-red-btn" btnTxt={t("Register")} />
                                <p className="text-center">{t('Already have an account?')} <span className="theme-red">{t('Login')}</span></p>
                            </form>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </>
  )
}

export default IntroducingBroker
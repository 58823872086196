import { useEffect, useRef } from "react";
import './Experience.css'
import ExpCard from './ExpCard'
import Land1 from "../../../assets/svg/land-1.svg"
import CoinExchange from "../../../assets/svg/coin-exchange-1.svg"
import Internet from "../../../assets/svg/internet-1.svg"
import MetaVerse from "../../../assets/svg/metaverse-city-1.svg"
import { useTranslation } from "react-i18next";


const Experience = () => {
    const {t} = useTranslation();
    const expRef = useRef(null);

    useEffect(() => {
        const expObserver = new IntersectionObserver(entry =>{
            if (entry[0].isIntersecting){
                const container = document.querySelector(".experience-section");
                const boxes = container.querySelectorAll('.card');

                for (const box of boxes) {
                    box.classList.add('show');
                }
            }
        }, 
        {
          threshold: 0.5,
        //   rootMargin: '-100px',
        }
        )
        expObserver.observe(expRef.current);
        
        return () => {
          expObserver.disconnect();
        };
      }, []);

  return (
    <div className="section experience-section" ref={expRef}>
    <div className='container'>
        <div className='col-md-12'>
            <div className='row justify-content-center text-center'>
                <h2 className='mb-5' style={{"width": "576px"}}>{t('More than 25 Years of Experience')}</h2>
            </div>

            <div className='row'>
                <div className='col-md-3 col-6'>
                    <ExpCard title={t("Razor Thin Spreads")} icon={Land1} />
                </div>
                <div className='col-md-3 col-6'>
                    <ExpCard title={t("1:500 Leverage")} icon={CoinExchange} />
                </div>
                <div className='col-md-3 col-6'>
                    <ExpCard title={t("24/7 Client Support")} icon={Internet} />
                </div>
                <div className='col-md-3 col-6'>
                    <ExpCard title={t("500+ Trading Products")} icon={MetaVerse} />
                </div> 
            </div>
        </div>
    </div>
    </div>
  )
}

export default Experience;
import PaymentGateways from "../../../assets/supreme-accounts.png";
import "../../../index.css";
import "./TransferFunds.css";
import { useTranslation } from "react-i18next";

const TransferFunds = () => {
  const {t} = useTranslation();

  return (
    <div className="section transfer-fund-section" style={{"backgroundColor": "#fff"}}>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex align-items-center h-100" style={{"paddingRight": "50px"}}>
                <div>
                <h4 className="mb-5">{t('Quickly Transfer Funds To And From Your SupremeFX Trading Account')}</h4>
                <p className="p2">{t('Select from a variety of secure, instant, and easy deposit/withdrawal options available for a stress-free trading experience. Get prompt assistance on account deposits queries with zero additional costs.')}</p>
                </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="footer-logo">
              <img loading="lazy" className="w-100" src={PaymentGateways} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferFunds;

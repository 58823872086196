import { useTranslation } from "react-i18next"
import AccountTypeCard from "../AcountType/AccountTypeCard"

const AccountTypes = () => {
    const {t} = useTranslation();

    const standardArray = [t('Spread From 1.2 pips'), t('Leverage 1:500'), t('Minimum Deposit $100'), t('Zero Commission')]

    const advancedArray = [t('SpreadFrom 0.8 pips'), t('Leverage 1:500'), t('Minimum Deposit $1.000'), t('Zero Commission')]
    
    const proArray = [t('SpreadFrom 0.1 to 0.2 pips'), t('Leverage 1:200'), t('Minimum Deposit $2.500'), t('Zero Commission')]

    
  return (
    <div className="section company-account-type">
        <div className="container">
            <div className='col-md-12'>
                    <div className='row text-center mb-5'>
                        <h4>{t('Account Types')}</h4>
                    </div>
                    <div className='row'>
                        <AccountTypeCard title={t("Standard")} content={standardArray} />
                        <AccountTypeCard title={t("Advanced")} content={advancedArray} />
                        <AccountTypeCard title={t("PRO")} content={proArray} />
                    </div>
                </div>
        </div>
    </div>
  )
}

export default AccountTypes
import "./CoreValues.css"
import Iphone from "../../assets/iPhone.png"
import Consistency from "../../assets/consistency.png"
import Customer from "../../assets/customer-centric.png"
import { useTranslation } from "react-i18next"

const CoreValues = () => {
  const {t} = useTranslation();

  return (
    <div className='section core-values-section'>
      <div className='container'>
        <div className='col-lg-12'>
          <div className="row">
            <div className="heading">
              <h3>{t('Core Values')}</h3>
            </div>
          </div>

          <div className='row'>
            <div className='col-lg-4'>
              <CoreValuesCard count="1" title={t("Integrity")} image={Iphone} content={t("Supreme FX has chosen to operate as a Straight Through Processing (STP) broker. Clients of Supreme FX can comfortably trade or run their automated systems without worrying that their orders are manipulated or reviewed by a dealer. At Supreme FX clients always trade hassle-free.")} />
            </div>
            <div className='col-lg-4'>  
              <CoreValuesCard count="2" title={t("Consistency")} image={Consistency} content={t("Stable execution is paramount in forex trading. For this reason Supreme FX only cooperates with leading technology vendors who can ensure stability both day in and day out.")} />
            </div>
            <div className='col-lg-4'>  
              <CoreValuesCard count="3" title={t("Customer Centricity")} image={Customer} content={t("How can we optimise the user experience? What are our clients needs? Is the focus of our innovations customer-first? This is how we think. This is how we execute.")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export const CoreValuesCard = (props) => {
  return (
    <div className='card'>
      <h4>{props.count}</h4>
      <div className='title-image'>
        <h6>{props.title}</h6>
        <img src={props.image} alt='' />
      </div>

      <div className='content'>
        <p className='p4'>{props.content}</p>
      </div>
    </div>
  )
}


export default CoreValues
import "./Team.css"
import TeamImg from "../../assets/our-team.png";
import ParaLine from "../../assets/svg/team-para-line-1.svg";
import { useTranslation } from 'react-i18next'

const Team = () => {
  return (
    <div className="section team-section" style={{ backgroundColor: "#fff" }}>
      <div className="container">
        <div className="col-lg-12">
          <div className="row">
            <TeamContent />
          </div>
          
          {/* <div className="row">
            <div className="heading text-center">
              <h4>{t('Our Team')}</h4>
            </div>
          </div>

          <div className="custom-flex">
            <TeamCard />
            <TeamCard />
            <TeamCard />
            <TeamCard />
            <TeamCard />
            <TeamCard />
            <TeamCard />
            <TeamCard />
            <TeamCard />
            <TeamCard />
          </div> */}
        </div>
      </div>
    </div>
  );
};


export const TeamContent = () => {
  const {t} = useTranslation();
  
  return (
    <div className="team-para">
      <div>
      <img src={ParaLine} alt="" />
      </div>
      <div>
      <h6>{t('Supreme FX has a team of professional and experienced analysts, accountants, customer service, HR, and legal. This team all have the same mission – to provide the highest service to you.')}</h6>
      </div>
    </div>
  )
}


export const TeamCard = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="image">
          <img src={TeamImg} alt="" />
        </div>

        <div className="content">
          <h6>Aspen Baptista</h6>
          <p className="p2">Admin</p>
          <p className="p2 experience">
            10 years of experience in wealth management
          </p>
        </div>
      </div>
    </div>
  );
};

export default Team;

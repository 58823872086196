import Footer from "../Common/Footer/Footer"
import Navigation from "../Common/Header/Header"
import ProductCard from "./ProductCard"
import Forex from "../../assets/forex.png"
import Metals from "../../assets/metals.png"
import Indices from "../../assets/indices.png"
import Energies from "../../assets/energies.png"
import Stocks from "../../assets/stocks.png"
import "./OurProduct.css"
import { useTranslation } from "react-i18next"

const OurProduct = () => {
  const {t} = useTranslation();
  return (
    <>
    <Navigation />
    <div className="section our-product-section">
      <div className="container">
        <div className="row">
          <div className="heading">
            <h1>{t('Our Products')}</h1>
            <p className="p2">{t('Invest in ever-growing industries')}</p>
          </div>
        </div>

        <div className="our-products">
        <ProductCard image={Forex} title={t("Forex")} btnTxt={t("Invest in Forex")} content={t("Trade on 60+ FX pairs including Majors, Minors and Exotics with your Accuindex trading account and enjoy the lowest spreads in the region varying on our three different account types")} />
        <ProductCard image={Metals} title={t("Metals")} btnTxt={t("Invest in Metals")} content={t("With the ability to trade CFD’s on precious metals, you get to enjoy all the diversification and speculation benefits without having to physically own the precious metals.")} />
        <ProductCard image={Indices} title={t("Indices")} btnTxt={t("Invest in Indices")} content={t("Trade CFD’s on popular indices with your Accuindex account and get access to the biggest global stock indices including NASDAQ, German DAX, and Dow Jones with low margin requirements and the tightest spreads.")} />
        <ProductCard image={Energies} title={t("Energies")} btnTxt={t("Invest in Energies")} content={t("Trade CFD’s on energies like oil and gas with you Accuindex account is a great way to diversify your portfolio. We offer you access to those markets without having to purchase the product outright")} />
        <ProductCard image={Stocks} title={t("Stocks")} btnTxt={t("Invest in Stock")} content={t("From Apple to Microsoft, Tesla to Facebook, Netflix to Amazon, and thousands of others – leveraged products on shares give everyone the chance to invest in the companies they are interested in.")} />
        </div>
      </div>
    </div>
    <Footer />
    </>
  )
}

export default OurProduct
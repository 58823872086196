import CallToActions from "../Home/CTA/CallToActions"
import lottie6 from "../../assets/animations/Lottie06.json";
import lottie7 from "../../assets/animations/Lottie07.json";
import LiveStockCard from "../Home/LiveStocks/LiveStockCard";
import NewLiveStock from "../Home/LiveStocks/NewLiveStock"
import { useTranslation } from "react-i18next";
import ForexCard from "../Home/LiveStocks/ForexCard";
import forexData from '../Home/LiveStocks/ForexData.json'
import MSFT from '../../assets/trading/MSFT.webp'
import AAPL from '../../assets/trading/AAPL.webp'
import GOOG from '../../assets/trading/GOOG.webp'
import TSLA from '../../assets/trading/TSLA.webp'
import AMZN from '../../assets/trading/AMZN.webp'
import NFLX from '../../assets/trading/NFLX.webp'

const MissionVision = () => {
    const {t} = useTranslation();

    let data = [
        {
          "Global Quote": {
              "01. symbol": "MSFT",
              "02. open": "370.6650",
              "03. high": "373.1000",
              "04. low": "367.1700",
              "05. price": "367.9400",
              "06. volume": "20901502",
              "07. latest trading day": "2024-01-04",
              "08. previous close": "370.6000",
              "09. change": "-2.6600",
              "10. change percent": "-0.7178%"
          }
        },
        {
          "Global Quote": {
              "01. symbol": "IBM",
              "02. open": "160.2200",
              "03. high": "161.8100",
              "04. low": "160.1700",
              "05. price": "160.8600",
              "06. volume": "3212004",
              "07. latest trading day": "2024-01-04",
              "08. previous close": "160.1000",
              "09. change": "0.7600",
              "10. change percent": "0.4747%"
          }
        },
        {
          "Global Quote": {
              "01. symbol": "AAPL",
              "02. open": "182.1500",
              "03. high": "183.0872",
              "04. low": "180.8800",
              "05. price": "181.9100",
              "06. volume": "71983570",
              "07. latest trading day": "2024-01-04",
              "08. previous close": "184.2500",
              "09. change": "-2.3400",
              "10. change percent": "-1.2700%"
          }
        },
        {
          "Global Quote": {
              "01. symbol": "GOOG",
              "02. open": "139.8500",
              "03. high": "140.6350",
              "04. low": "138.0100",
              "05. price": "138.0400",
              "06. volume": "18253331",
              "07. latest trading day": "2024-01-04",
              "08. previous close": "140.3600",
              "09. change": "-2.3200",
              "10. change percent": "-1.6529%"
          }
        },
        {
          "Global Quote": {
              "01. symbol": "TSLA",
              "02. open": "239.2500",
              "03. high": "242.7000",
              "04. low": "237.7300",
              "05. price": "237.9300",
              "06. volume": "102227391",
              "07. latest trading day": "2024-01-04",
              "08. previous close": "238.4500",
              "09. change": "-0.5200",
              "10. change percent": "-0.2181%"
          }
        },
        {
          "Global Quote": {
              "01. symbol": "AMZN",
              "02. open": "145.5900",
              "03. high": "147.3800",
              "04. low": "144.0500",
              "05. price": "144.5700",
              "06. volume": "56039807",
              "07. latest trading day": "2024-01-04",
              "08. previous close": "148.4700",
              "09. change": "-3.9000",
              "10. change percent": "-2.6268%"
          }
        },
        {
          "Global Quote": {
              "01. symbol": "NFLX",
              "02. open": "472.9800",
              "03. high": "480.7393",
              "04. low": "466.5300",
              "05. price": "474.6700",
              "06. volume": "3636513",
              "07. latest trading day": "2024-01-04",
              "08. previous close": "470.2600",
              "09. change": "4.4100",
              "10. change percent": "0.9378%"
          }
        }
      ]

  return (
    <div className="section mission-vision-section">
        <div className="container">
            <div className="col-lg-12">
                <CallToActions title={t("Mission")} para={t("We are committed to providing a conducive environment. by offering a vast range of markets and instruments on a trusted, reliable, and fast trading platform, with zero commissions and tight spreads, so that our traders can focus on executing them. winning strategies and achieving their financial goals")}
                image={lottie7} divID="lottie6"
                />

                <div className="vision position-relative">
                    <CallToActions title={t("Vision")} para={t("To make SUPREME FX 'THE BRAND' for traders by being the world's most reliable and trusted broker")}
                    image={lottie6} divID="lottie7" order="order-md-2"
                    />

                    <svg id="bg-vision" xmlns="http://www.w3.org/2000/svg" width="500" height="490" viewBox="0 0 500 490" fill="none">
                        <path d="M0 210L500 0V280L0 490V210Z" fill="url(#paint0_linear_1324_7124)"/>
                        <defs>
                        <linearGradient id="paint0_linear_1324_7124" x1="250" y1="0" x2="386.282" y2="328.829" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#C2D5EF"/>
                        <stop offset="1" stopColor="#C2D5EF" stopOpacity="0"/>
                        </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
		
		<NewLiveStock />
		
		{/*
        <div className='live-stock-section'>
          <div className='row d-block forex-cards-animations'>
              <ForexCard data={forexData[0]} />
              <ForexCard data={forexData[1]} />
              <ForexCard data={forexData[2]} />
              <ForexCard data={forexData[3]} />
              <ForexCard data={forexData[4]} />
              <ForexCard data={forexData[0]} />
              <ForexCard data={forexData[1]} />
          </div>

          <div className="row d-block cards-animations">
              <LiveStockCard data={data[0]} image={MSFT} />
              <LiveStockCard data={data[1]} image={MSFT} />
              <LiveStockCard data={data[2]} image={AAPL} />
              <LiveStockCard data={data[3]} image={GOOG} />
              <LiveStockCard data={data[4]} image={TSLA} />
              <LiveStockCard data={data[5]} image={AMZN} />
              <LiveStockCard data={data[6]} image={NFLX} />
          </div>
        </div>
		*/}
    </div>
  )
}

export default MissionVision
import { useTranslation } from "react-i18next"
import Founder from "../About/Founder"

const Leader = () => {
    const {t} = useTranslation();

  return (
    <div className="section leadership-section">
        <div className="container">
            <div className="heading">
                <h5>{t('A Message From Our Leadership')}</h5>
            </div>
            <Founder />

            <div className="leadership-content">
                <div className="content">
                    <h6>{t('On being a leading and highly successful Forex broker. With a solid track record and a commitment to excellence, we have emerged as a trusted partner for traders worldwide.')}</h6>
                    <h6>{t('Our unwavering focus on delivering exceptional trading experiences, innovative technologies, and a client-centric approach has established us as a preferred choice in the competitive Forex industry.')}</h6>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Leader
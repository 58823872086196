import Navigation from "../Common/Header/Header"
import Hero1 from "./Hero/Hero1"
import InvestmentPlatform from "./InvestmentPlatform/InvestmentPlatform"
import CTA from "./CTA/CTA"
import LiveStocks from "./LiveStocks/LiveStocks"
import NewLiveStock from "./LiveStocks/NewLiveStock"
import LearnMore from "./LearnMore/LearnMore"
import Experience from "./Experience/Experience"
import TransferFunds from "./TransferFunds/TransferFunds"
import Features from "./Features/Features"
import Footer from "../Common/Footer/Footer"
import ScrollTop from "../Common/ScrollTop/ScrollTop"
import Testimonial from "./Testimonial/Testimonial"

const Home = () => {
  return (
    <>
    <Navigation />
    <ScrollTop />
    <Hero1 />
    <InvestmentPlatform />
    <CTA />
    <NewLiveStock />
    {/* <LiveStocks /> */}
    <LearnMore />
    <Experience />
    <Testimonial />
    <TransferFunds />
    <Features />
    <Footer />
    </>
  )
}

export default Home
import { Link } from "react-router-dom";
import Button from "../Common/Button/Button"
import { useTranslation } from "react-i18next"

const ProductCard = (props) => {
    const {t} = useTranslation();

  return (
    <div className='card'>
        {/* <div className='card-body'> */}
            {/* <div className='product-card'> */}
                <div className='image'>
                    <img src={props.image} alt='' />
                </div>
                <div className='content'>
                    <h6>{props.title}</h6>
                    <p className='p4'>{props.content}</p>
                </div>

                <div className='learn-more'>
                    <p className='p2'>{t('Learn More')}</p>
                </div>
            {/* </div> */}
        {/* </div> */}
        <Link to='/account-type/'>
        <Button className="theme-red-btn" btnTxt= {props.btnTxt}/>
        </Link>
    </div>
  )
}

export default ProductCard
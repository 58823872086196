import { useState } from "react";
import ScrollTopImg from "../../../assets/scroll-top.png";

const ScrollTop = () => {
  let btnStyle = {
    position: "fixed",
    bottom: "5%",
    // right: "8px",
    insetInlineStart: "8px",
    width: "50px",
    height: "50px",
    zIndex: "9",
    transform: "rotate(225deg)",
    cursor: "pointer",
  };
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return <img src={ScrollTopImg} onClick={scrollToTop} style={btnStyle} />;
};
export default ScrollTop;

import React, {useRef, useEffect, useState} from "react";
import TradeImage from "../../../assets/svg/trade.svg";
import PartnerImage from "../../../assets/svg/partner.svg"
import HalalAccountImage from "../../../assets/svg/halal-account.svg";
import AboutImage from "../../../assets/svg/about-1.svg";
import ArrowLight from "../../../assets/svg/arrow-light.svg";
import ArrowDark from "../../../assets/svg/arrow-dark.svg";
import Card from "./Cards";
import "../../../index.css"
import './InvestmentPlatform.css'
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const InvestmentPlatform = () => {
  const investmentRef = useRef(null);
  const {t} = useTranslation();
  const [registrationLink, setRegistrationLink] = useState('');
  const [portalLink, setPortalLink] = useState('');
  const [redirectLang, setRedirectLang] = useState('');



  let accountSvg = <svg xmlns="http://www.w3.org/2000/svg" width="240" height="16" viewBox="0 0 240 16" fill="none">
              <path style={{"mix-blend-mode":"multiply"}} opacity="0.5" d="M119.897 16.0002C186.115 16.0002 239.84 12.4925 239.897 8.1655C239.954 3.83852 186.32 0.330811 120.103 0.330811C53.8858 0.330811 0.159959 3.83852 0.103098 8.1655C0.0462359 12.4925 53.6799 16.0002 119.897 16.0002Z" fill="url(#paint0_radial_1324_14131)"/>
              <defs>
                <radialGradient id="paint0_radial_1324_14131" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(120.005 8.56081) rotate(-180) scale(119.928 7.78812)">
                  <stop offset="0.6" stopColor="#B5A59A"/>
                  <stop offset="0.64" stopColor="#CBBFB8"/>
                  <stop offset="0.69" stopColor="#DDD6D1"/>
                  <stop offset="0.74" stopColor="#ECE8E5"/>
                  <stop offset="0.8" stopColor="#F7F5F4"/>
                  <stop offset="0.88" stopColor="#FDFCFC"/>
                  <stop offset="1" stopColor="white"/>
                </radialGradient>
              </defs>
            </svg>

  let aboutSvg = <svg xmlns="http://www.w3.org/2000/svg" width="240" height="16" viewBox="0 0 240 16" fill="none">
                  <path style={{"mix-blend-mode":"multiply"}} opacity="0.2" d="M119.897 15.9973C186.114 15.9973 239.84 12.4789 239.897 8.13866C239.954 3.79846 186.32 0.280029 120.102 0.280029C53.8849 0.280029 0.159079 3.79846 0.102336 8.13866C0.0455927 12.4789 53.6794 15.9973 119.897 15.9973Z" fill="url(#paint0_radial_1324_14137)"/>
                  <defs>
                    <radialGradient id="paint0_radial_1324_14137" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(120.004 8.53518) rotate(-180) scale(119.928 7.81192)">
                      <stop offset="0.6" stopColor="#FF5E6B"/>
                      <stop offset="0.64" stopColor="#FF6875"/>
                      <stop offset="0.688685" stopColor="#FF747F"/>
                      <stop offset="0.74" stopColor="#FF8D97"/>
                      <stop offset="0.8" stopColor="#FFA0A8"/>
                      <stop offset="0.88" stopColor="#FFBFC5"/>
                      <stop offset="1" stopColor="white"/>
                    </radialGradient>
                  </defs>
                </svg>          

  useEffect(() => {
    if (i18next.language === 'ar'){
        setRedirectLang('?lead_source=website');
      } else{
        setRedirectLang('?lead_source=website');
      }

    if(window.location.hostname === 'supremefxtrading.sv' || window.location.hostname === 'www.supremefxtrading.sv'){
      setRegistrationLink(`https://secure.supremefxtrading.sv/register${redirectLang}`);
      setPortalLink('https://portal.supremefxtrading.sv/');
    }else if(window.location.hostname === 'supremefxtrading.net' || window.location.hostname === 'www.supremefxtrading.net'){
		setRegistrationLink(`https://secure.supremefxtrading.net/register`);
		setPortalLink('https://portal.supremefxtrading.net/');
	}else{
      setRegistrationLink(`https://secure.supremefxtrading.com/register${redirectLang}`);
      setPortalLink('https://portal.supremefxtrading.com/');
    }

    const investmentObserver = new IntersectionObserver(entry =>{
        if (entry[0].isIntersecting){
          entry[0].target.classList.add('investmentBGFade'); 
          investmentObserver.unobserve(entry[0].target);
        }
      }, 
      {
        threshold: 0,
      }
    )
    investmentObserver.observe(investmentRef.current);
    
    return () => {
      investmentObserver.disconnect();
    };
  }, [i18next.language, redirectLang]);

  return (
    <div className="section investment-section" ref={investmentRef}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="w-50 heading">
              <h2>{t('Your all-in-one investment platform')}</h2>
              <p className="p1-24">{t('Innovative trading platform equipped with the best tools & indicators to help you achieve a successful trading journey.')}</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 mb-4">
            <Card btnText={t("Open Live Account")} title={t("Trade with just 100$")} bgColor="#fff" bgImage={TradeImage} arrowImg={ArrowDark} btnLink={registrationLink} arrowLink={registrationLink} btnLinkNewTab arrowLinkNewTab />
          </div>
          <div className="col-md-6 mb-4">
            <Card btnText={t("Register as IB")} btnTextColor="#fff" title={t("Become a partner")}  bgColor="#FF4755" bgImage={PartnerImage} arrowImg={ArrowLight} btnLink={portalLink} arrowLink="/introducing-broker/" btnLinkNewTab />
          </div>
          <div className="col-md-6 mb-4">
            <Card btnText={t("Go Halal")} btnTextColor="#fff" title={t("Open your Halal Account")} para={t("0% Interest")}  bgColor="#4D535F" bgImage={HalalAccountImage} arrowImg={ArrowLight} btnLink={registrationLink} arrowLink="/account-type/" svg={accountSvg} btnLinkNewTab />
          </div>
          <div className="col-md-6 mb-4">
            <Card btnText={t("Learn More")} title={t("About Us")} bgImage={AboutImage} arrowImg={ArrowDark} btnLink="/about/" arrowLink="/about/" svg={aboutSvg} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestmentPlatform;

import React, {useEffect, useRef, useState} from "react";
import Lottie from "lottie-web";
import './CTA'
import "./CallToActions.css"
import { Link } from "react-router-dom";
import Button from "../../Common/Button/Button";
import { useTranslation } from "react-i18next";

const CallToActions = (props) => {  
  const {t} = useTranslation();
  const[animCount, setAnimCount] = useState(0);
  const ref = useRef(null);
  let lott;


  useEffect(() => {
    // console.log('animCount:', animCount);
    lott = Lottie.loadAnimation({
      name: `${props.divID}`,
      container: document.querySelector(`#${props.divID}`),
      loop: false,
      autoplay: false,
      animationData: props.image,
    });

    const observer = new IntersectionObserver(entry =>{
        if (entry[0].isIntersecting){
          entry[0].target.classList.add('ctaBGFade');
          lott.play();
          setAnimCount(0);
          observer.unobserve(entry[0].target);
        }
    }, 
    {
      threshold: 0.5,
    }
    )
    observer.observe(ref.current);
    
    return () => {
      setAnimCount(0);
      lott.destroy();
      observer.disconnect();
    };
  }, [animCount]);

  let para2;
  let li;
  let btn;

  if(props.para2){
    para2 = <p>{props.para2}</p>
  }
  if(props.liItems){
    li = <ul>
      <li>{t('Fund managers')}</li>
      <li>{t('Signal providers')}</li>
      <li>{t('Multi-account managers')}</li>
    </ul>
  }
  if(props.isBtn){
    btn = <Link to={props.link} style={{"position": "relative"}} target="_blank" rel="noreferrer"><Button className="theme-red-btn" btnTxt={props.btnText} /></Link>
  }

  return (
    <div className="row" style={{"position": "relative"}} ref={ref}>
      <div className={`col-md-5 ${props.order}`}>
        <div
          className="d-flex align-items-center h-100"
          style={{ paddingRight: "50px" }}
        >
          <div>
            <h4 className="mb-4">
              {props.title}
            </h4>
            <p>{props.para}</p>
            {para2}
            {li}
            {btn}
          </div>
        </div>
      </div>

      <div className="col-md-7">
        <div className="footer-logo">
          {/* <div id={`${props.divID}`} onMouseEnter={()=>{(animCount >= 1)?setAnimCount(0):setAnimCount(animCount + 1)}}></div> */}
          <div id={`${props.divID}`}></div>
        </div>
      </div>
    </div>
  )
}

export default CallToActions;
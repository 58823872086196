import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Testimonial.css'
import Image from '../../../assets/testimonial.png'
import milos from '../../../assets/milos.png'
import ny from '../../../assets/ny.png'
import QuoteImg from '../../../assets/svg/quote-symbol.svg'
// import Testimonials from "./Testimonials.json"
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useEffect } from "react";

const Testimonial = () => {
  const {t} = useTranslation();

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: () => {
      // console.log('before change');
    },
    swipeEvent: (e) =>{
      console.log('SWIPE EVENT:', e);
    },
    onSwipe: (e) =>{
      console.log('ON SWIPE:', e);
    },
    afterChange: () =>{
      // console.log('after changed')
      if(document.body.getAttribute('dir') === 'rtl'){
        const test_slide = document.getElementsByClassName('slick-track')[0];
        const value = window.getComputedStyle(test_slide).getPropertyValue('transform'); 
        const split_value = value.split(',')
        const positive_value = split_value[4].replace('-', '');
        split_value[4] = positive_value;
        const final_value = split_value.join(',');
        test_slide.style.transform = final_value;
        }
    }
  };

  useEffect(() =>{
    const body_dir = document.body.getAttribute('dir');
    const slick_element = document.getElementsByClassName('slick-slider')[0];

    if(body_dir === 'rtl'){
      slick_element.setAttribute('dir', 'rtl');
      const test_slide = document.getElementsByClassName('slick-track')[0];
      const value = window.getComputedStyle(test_slide).getPropertyValue('transform'); 
      const split_value = value.split(',')
      const positive_value = split_value[4].replace('-', '');
      split_value[4] = positive_value;
      const final_value = split_value.join(',');
      test_slide.style.transform = final_value;
    } else{
      slick_element.setAttribute('dir', 'ltr');
      const test_slide = document.getElementsByClassName('slick-track')[0];
      const value = window.getComputedStyle(test_slide).getPropertyValue('transform'); 
      const split_value = value.split(',')
      const negative_value = split_value[4].replace(split_value[4], `-`+split_value[4]).replace(' ', '');
      split_value[4] = negative_value;
      const final_value = split_value.join(',');
      test_slide.style.transform = final_value;
    }
  }, [t])

  return (
    <div className="section testimonial-section">
      <div className="container">
        <h4 className="heading">{t('Testimonials')}</h4>
        <Slider {...settings}>
          {i18next.t('myText', { returnObjects: true }).map(({id, testimonial, user }) => (
              <TestimonialCard key={id} testimonial={testimonial} user={user} />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export const TestimonialCard = (props) => {
  return(
    <div className="card" key={props.key}>
        <div className="card-body">
            <div className="quote-image">
                <img src={QuoteImg} alt="" />
            </div>
            <div className="testimonial-text">
                <h5>{props.testimonial}</h5>
            </div>
            <div className="below-section">
                <div className="image">
				{(props.user == 'R. Ali') && (
					<img src={Image} alt="" />
				)}
				{(props.user == 'Milos. N') && (
					<img src={milos} alt="" />
				)}
				{(props.user == 'IB Partner: N. Younes') && (
					<img src={ny} alt="" />
				)}
                    
                </div>
                <div className="name">
                    <h6>{props.user}</h6>
                </div>
            </div>
        </div>
    </div>
  );
};

export default Testimonial;

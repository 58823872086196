import { Link } from "react-router-dom";
import "./Cards.css"

const Card = (props) => {
    let cardStyle = {
        "backgroundColor": `${props.bgColor}`,
        // "padding": "60px 40px",
        // "height": "650px",
        // "borderRadius": "32px",
    }
    // let mainImageStyle = {
    //     "position": "absolute",
    //     // "right": "10%",
    //     "insetInlineEnd": "10%",
    //     "width": "50%",
    //     "height": "auto",
    //     "top": "40%",
    // }
    let arrowImageStyle = {
        "position": "absolute",
        "bottom": "5%",
        "width": "10%",
    }
  return (
    <div className="card" style={cardStyle}>
      <div className="card-body">
        <Link to={props.btnLink} className="theme-light-btn" style={{'color': `${props.btnTextColor}`}} target={props.btnLinkNewTab ? "_blank" : "_self"} rel="noreferrer">
          {props.btnText}
        </Link>
        <h5 className="card-title mt-5" style={{"width": "65%", "color": `${props.btnTextColor}`}}>{props.title}</h5>
        <p style={{"color": `${props.btnTextColor}`}}>{props.para}</p>
        <div className="card-img">
          {/* <img src={props.bgImage} alt="" className="main-img" style={mainImageStyle} /> */}
          <img loading="lazy" src={props.bgImage} alt="" className="main-img" />
          {props.svg && props.svg}
        </div>
        
        <Link to={props.arrowLink} target={props.arrowLinkNewTab ? "_blank" : "_self"} rel="noreferrer">
            <img src={props.arrowImg} alt="" className="arrow-img" style={arrowImageStyle} />
        </Link>
      </div>
    </div>
  );
};

export default Card;

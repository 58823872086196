import AccordionItem from './AccordionItem';
import './Accordion.css'
import accordionData from "./FAQ's.json"
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const Accordion = () => {
  const {t} = useTranslation();

  return (
        <div className='accordion'>
        {/* {accordionData.map(({id, title, content }) => (
            <AccordionItem key={id} title={title} content={content} />
        ))} */}
        {i18next.t('myFAQ', { returnObjects: true }).map(({id, title, content }) => (
            <AccordionItem key={id} title={title} content={content} />
        ))}
        </div>
  )
}

export default Accordion